<template>
  <div>
    <h3>10 September 2010 (0.0.35) </h3>
    <p>
      <u>Pet Cove</u>
      <br>- If you are out of interaction range with a pet, pet taming minigame terminates.
    </p>
    <p>
      <u>Racing</u>
      <br>- The lap time and track time requirements for both race tracks have been increased.
    </p>
    <p>
      <u>Property</u>
      <br>- The platform for the Maelstrom Pushback is now more reliable.
    </p>
    <h3>10 September 2010 (0.0.34)</h3>
    <p>
      <u>Front End</u>
      <br>- Max account password length increased to 40 to match LEGO.com.
    </p>
    <p>
      <u>Build</u>
      <br>- Non-BFF’s will not be able to enter your property when you are wearing your Thinking Hat.
    </p>
    <h3>10 September 2010 (0.0.33)</h3>
    <p>
      <u>Forbidden Valley</u>
      <br>- Dragon Instance icon is now displayed above the dragon.
    </p>
    <h3>10 September 2010 (0.0.32)</h3>
    <p>
      <u>Avant Gardens</u>
      <br>- The Survival Instance displays all 4 players and handles exiting correctly.
    </p>
    <p>
      <u>Property</u>
      <br>- Loot spawned on properties is now visible and can be picked up by any player on that property.
    </p>
    <p>
      <u>Build</u>
      <br>- Modular build will properly return multiple items of the same type that you have placed on the ground.
      <br>- Picking up a model and putting it back down does not delete its behaviors.
    </p>
    <p>
      <u>Behaviors</u>
      <br>- Behaviors can be given custom names.
    </p>
    <h3>8 September 2010 (0.0.31)</h3>
    <p>
      <u>Avant Gardens</u>
      <br>- You should now flash if getting hit by a Dark Spiderling.
    </p>
    <p>
      <u>Gnarled Forest</u>
      <br>- Lightning now flashes hot-white, rather than off-purple.
    </p>
    <p>
      <u>Build</u>
      <br>- The camera control scheme has been updated for Model Mode and Brick Mode.
    </p>
    <h3>8 September 2010 (0.0.30)</h3>
    <p>
      <u>Front End</u>
      <br>- The Character Create torsos are clickable again.
    </p>
    <p>
      <u>Audio</u>
      <br>- Stromling attack swing sound plays consistently now.
    </p>
    <h3>7 September 2010 (0.0.29)</h3>
    <p>
      <u>Behaviors</u>
      <br>- Fixed smashed behavior models moving through property boundaries.
    </p>
    <p>
      <u>Property</u>
      <br>- Property Warps now have a precondition that requires you to have leased the appropriate property to use them.
      <br>- Population removed from all property screens.
    </p>
    <p>
      <u>Nimbus Station</u>
      <br>- The Maelstrom console now costs 25 Maelstrom-infected Bricks instead of 20 and rewards 5 faction tokens instead of 1-5.
    </p>
    <p>
      <u>LEGO Club</u>
      <br>- Updated LEGO Club screen textures.
    </p>
    <h3>7 September 2010 (0.0.28)</h3>
    <p>
      <u>Front End</u>
      <br>- Getting stuck at submitting username for an extended period of time should no longer be an issue.
      <br>- The Add Hair icon/button now appears in Character Create if your Minifigure is bald.
    </p>
    <p>
      <u>Property</u>
      <br>- Public property / Best Friend buttons now show as disabled before the property tutorial missions have been completed.
    </p>
    <p>
      <u>Build</u>
      <br>- Smashing yourself in Brick Mode will no longer cause a blocking state, and your work will be saved.
      <br>- Many reward models have been updated to work correctly.
    </p>
    <p>
      <u>Behaviors</u>
      <br>- Dragging the last behavior from the behavior cloud to your inventory no longer deletes it.
      <br>- Models with behaviors now prevent other models from being placed on/in them in both Brick Mode and Model Mode.
    </p>
    <p>
      <u>Backpack</u>
      <br>- The Backpack icon will no longer show as full after completing a mission that expands your inventory size.
    </p>
    <p>
      <u>Quick-Builds</u>
      <br>- Turrets no longer hate their creator.
    </p>
    <p>
      <b>In preparation for launch in October, the release note numbering format changes from hereon forward.</b>
    </p>
    <h3>3 September 2010 (0.190.27)</h3>
    <p>
      <u>Venture Explorer</u>
      <br>- New glow map for Sky Lane has been fixed.
    </p>
    <p>
      <u>Forbidden Valley</u>
      <br>- Updated effects for Brick Fury.
      <br>- Mesh and particle effects have been added to the Paradox Facility.
      <br>- All Quick-Built pipes in the Paradox Refinery now last 35 seconds instead of 25.
    </p>
    <p>
      <u>Property</u>
      <br>- When doing a “social” search for properties, the resulting message will be based on whether you have already made friends or not.
    </p>
    <p>
      <u>Behaviors</u>
      <br>- You can no longer spawn something in or on an enemy.
    </p>
    <p>
      <u>Chat</u>
      <br>- Text will not appear in chat box when you emote.
    </p>
    <p>
      <u>User Interface</u>
      <br>-Selecting report abuse from dropdown menu will now bring up the appropriate selection in the help menu dropdown.
    </p>
    <p>
      <u>Audio</u>
      <br>- Crab, Mantis and Goat pets have the correct sounds.
      <br>- Forbidden Valley smashables now have the correct sounds.
    </p>
    <p>
      <u>General</u>
      <br>- Window movement, sizing and catching of bad sizing has been much improved.
    </p>
    <h3>2 September 2010 (0.190.26)</h3>
    <p>
      <u>Gnarled Forest</u>
      <br>- Shooting Gallery scores will now display correctly.
    </p>
    <p>
      <u>Forbidden Valley</u>
      <br>- Added some smashables on the ledge by the Dragon Instance loader.
    </p>
    <p>
      <u>Pet Cove</u>
      <br>- Nexus has been added to the skybox.
    </p>
    <p>
      <u>Property</u>
      <br>- Small property maps now have a Nexus effect.
      <br>- You will be prompted when you attempt to create more than the maximum allowed brick sorting groups (currently 50).
    </p>
    <p>
      <u>Audio</u>
      <br>- Updated spider sounds.
      <br>- More front end sounds.
    </p>
    <p>
      <u>General</u>
      <br>- Referee Bob has been replaced by a Mythran on the News Screen.
      <br>- Chained achievements in the Passport will now display all of the completed missions, not just the final one.
    </p>
    <h3>1 September 2010 (0.190.25)</h3>
    <p>
      <u>Avant Gardens</u>
      <br>- The text in the Survival Instance is more legible now.
    </p>
    <p>
      <u>Gnarled Forest</u>
      <br>- The Stromling captain now has an accurate image in the user interface.
    </p>
    <p>
      <u>Forbidden Valley</u>
      <br>- The chest in the Dragon Instance will now drop coins for all players in the team.
      <br>- Ronin and Horsemen got bored just standing around and now strol.
    </p>
    <p>
      <u>Behaviors</u>
      <br>- Clicking and dragging on a model with movement will no longer cause the graphical user interface to remain open.
      <br>- After applying and removing Behaviors from a model, it will no longer stack with identical models in the Backpack.
    </p>
    <p>
      <u>Build</u>
      <br>- Property saves are now done at character save times, which should greatly reduce the possible window for data loss on properties.
    </p>
    <p>
      <u>Trade</u>
      <br>- You will be informed when another player declines trade or is otherwise busy.
    </p>
    <p>
      <u>General</u>
      <br>- Particle effects should now show up correctly.
    </p>
    <p>
      <u>Screenshots</u>
      <br>- The default location for your in-game screenshots will be the My Pictures folder the LEGO Universe folder (when you first bring up the client, it will move over all your existing screenshots automatically).
      <br>- The default screenshot format has been changed from Bitmap to JPEG (this can still be altered by changing the SCREENSHOT_FORMAT entry in the lwo.xml configuration file.
    </p>
  </div>
</template>
